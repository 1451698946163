import {ethers} from "ethers";
import Web3 from "web3"

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
// const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = new Web3(alchemyKey);
// const web23 = createAlchemyWeb3(alchemyKey);

const contractABI = require("../contract-abi.json");
const bridgecontractABI = require("../bridge-contract-abi.json");
const stakeContractABI = require("../stake-abi.json");

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_ETH;
const bridgeAddress = process.env.REACT_APP_BRIDGE_ADDRESS_ETH;
const stakeAddress = process.env.REACT_APP_STAKE_ADDRESS_BSC;


export const smartContract = new web3.eth.Contract(
    contractABI,
    contractAddress
);

export const bridgeContract = new web3.eth.Contract(
    bridgecontractABI,
    bridgeAddress
);

export const stakeContract = new web3.eth.Contract(
    stakeContractABI,
    stakeAddress
);

export const loadContractName = async () => {
    const message = await smartContract.methods.name().call();
    return message;
};

export const loadContractSymbol = async () => {
    const message = await smartContract.methods.symbol().call();
    return message;
};

export const loadContractTotalSupply = async () => {
    const message = await smartContract.methods.totalSupply().call();
    return message;
};

export const loadContractDecimals = async () => {
    const message = await smartContract.methods.decimals().call();
    return message;
};

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "👆🏽 Write a message in the text-field above.",
                address: addressArray[0],
            };
            return obj;
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "👆🏽 Populate the Data and Click on Button to execute...",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
          <p>
            {" "}
              🦊{" "}
              <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
            ),
        };
    }
};

export const getAccountBalance = async (address) => {
    //input error handling
    if (!window.ethereum || address === null) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }
    const message = await smartContract.methods.balanceOf(address).call();
    return message;

};

export const approve = async (address, transferAddress, amount) => {
    //input error handling
    if (!window.ethereum || address === null || transferAddress === null) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: smartContract.methods.approve(transferAddress, amount).encodeABI(),
    };

    //sign the transaction
    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            status: (
                <span>
          ✅{" "}
                    <a target="_blank" rel="noreferrer" href={`https://ropsten.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
        </span>
            ),
        };
    } catch (error) {
        return {
            status: "😥 " + error.message,
        };
    }
};

export const deposit = async (address, transferAddress, amount, network) => {
    //input error handling
    if (!window.ethereum || address === null || transferAddress === null) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }

    const destinationId = network === "moonbeam" ? 2 : 3;

    const depositData = createERCDepositData(
        ethers.BigNumber.from(amount),
        20,
        transferAddress);


    //set up transaction parameters
    const transactionParameters = {
        to: bridgeAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: bridgeContract.methods.deposit(destinationId, toHex(0), depositData).encodeABI(),
    };

    //sign the transaction
    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            status: (
                <span>
          ✅{" "}
                        <a target="_blank" rel="noreferrer" href={`https://ropsten.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
        </span>
            ),
        };
    } catch (error) {
        return {
            status: "😥 " + error.message,
        };
    }
};

export const stake = async (address, amount) => {
    //input error handling
    if (!window.ethereum || address === null) {
        return {
            status:
                "💡 Connect your Metamask wallet to update the message on the blockchain.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: stakeAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: stakeContract.methods.stake(ethers.BigNumber.from(amount)).encodeABI(),
    };

    //sign the transaction
    try {
        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameters],
        });
        return {
            status: (
                <span>
          ✅{" "}
                    <a target="_blank" rel="noreferrer" href={`https://ropsten.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
        </span>
            ),
        };
    } catch (error) {
        return {
            status: "😥 " + error.message,
        };
    }
};

const toHex = (covertThis, padding) => {
    return ethers.utils.hexZeroPad(ethers.utils.hexlify(covertThis), padding);
};


const createERCDepositData = (tokenAmountOrID, lenRecipientAddress, recipientAddress) => {
    return '0x' +
        toHex(tokenAmountOrID, 32).substr(2) +      // Token amount or ID to deposit (32 bytes)
        toHex(lenRecipientAddress, 32).substr(2) + // len(recipientAddress)          (32 bytes)
        recipientAddress.substr(2);               // recipientAddress               (?? bytes)
};
